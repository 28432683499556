import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const PowersuppliesPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Power Supplies March 2024</title>
        <meta name="description" content="Explore the best power supplies March 2024, chosen based on expert reviews. Find the perfect power supplies for your needs." />
        <meta name="keywords" content="power supplies" />
      </Helmet>

      <h1>Top Power Supplies <span className='dato'> March 2024</span></h1>
      <section>
        <h2>1. Corsair AX1600i</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Corsair+AX1600i" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Corsair RM750x</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Corsair+RM750x" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default PowersuppliesPage;
