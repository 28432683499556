import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const GamingkeyboardsPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Gaming Keyboards May 2024</title>
        <meta name="description" content="Explore the best gaming keyboards May 2024, chosen based on expert reviews. Find the perfect gaming keyboards for your needs." />
        <meta name="keywords" content="gaming keyboards" />
      </Helmet>

      <h1>Top Gaming Keyboards <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Asus ROG Azoth</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Asus+ROG+Azoth" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Razer BlackWidow V4 Pro</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Razer+BlackWidow+V4+Pro" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Razer DeathStalker V2 Pro</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Razer+DeathStalker+V2+Pro" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. Razer BlackWidow V4 75</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Razer+BlackWidow+V4+75" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>5. Asus ROG Strix Scope II 96 Wireless</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Asus+ROG+Strix+Scope+II+96+Wireless" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default GamingkeyboardsPage;
