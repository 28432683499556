import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const JuicersPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Juicers April 2024</title>
        <meta name="description" content="Explore the best juicers April 2024, chosen based on expert reviews. Find the perfect juicers for your needs." />
        <meta name="keywords" content="juicers" />
      </Helmet>

      <h1>Top Juicers <span className='dato'> April 2024</span></h1>
      <section>
        <h2>1. Smeg Citrus Juicer</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Smeg+Citrus+Juicer" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Hamilton Beach Big Mouth Juice Extractor</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Hamilton+Beach+Big+Mouth+Juice+Extractor" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default JuicersPage;
