import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const SmartphonesPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Smartphones May 2024</title>
        <meta name="description" content="Explore the best smartphones May 2024, chosen based on expert reviews. Find the perfect smartphones for your needs." />
        <meta name="keywords" content="smartphones" />
      </Helmet>

      <h1>Top Smartphones <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Samsung Galaxy S24 Ultra</h2>
        <p><span className="price-highlight">RR Rating: ★★★★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Samsung+Galaxy+S24+Ultra" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Google Pixel 8 Pro</h2>
        <p><span className="price-highlight">RR Rating: ★★★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Google+Pixel+8+Pro" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Google Pixel 8a</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Google+Pixel+8a" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. OnePlus 12</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=OnePlus+12" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>5. Motorola Razr Plus</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Motorola+Razr+Plus" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default SmartphonesPage;
