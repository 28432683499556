import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const ExercisebikesPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Exercise Bikes April 2024</title>
        <meta name="description" content="Explore the best exercise bikes April 2024, chosen based on expert reviews. Find the perfect exercise bikes for your needs." />
        <meta name="keywords" content="exercise bikes" />
      </Helmet>

      <h1>Top Exercise Bikes <span className='dato'> April 2024</span></h1>
      <section>
        <h2>1. Schwinn IC4</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Schwinn+IC4" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Bowflex VeloCore 16</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Bowflex+VeloCore+16" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default ExercisebikesPage;
