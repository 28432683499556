import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const MirrorlesscamerasPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Mirrorless Cameras May 2024</title>
        <meta name="description" content="Explore the best mirrorless cameras May 2024, chosen based on expert reviews. Find the perfect mirrorless cameras for your needs." />
        <meta name="keywords" content="mirrorless cameras" />
      </Helmet>

      <h1>Top Mirrorless Cameras <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Canon EOS R5</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Canon+EOS+R5" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Canon EOS R7</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Canon+EOS+R7" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Olympus OM-D E-M10 M</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Olympus+OM-D+E-M10+M" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. Fujifilm X-T5</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Fujifilm+X-T5" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>5. Sony A7 IV</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Sony+A7+IV" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default MirrorlesscamerasPage;
