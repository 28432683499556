import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const DatastoragedevicesPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Data Storage Devices March 2024</title>
        <meta name="description" content="Explore the best data storage devices March 2024, chosen based on expert reviews. Find the perfect data storage devices for your needs." />
        <meta name="keywords" content="data storage devices" />
      </Helmet>

      <h1>Top Data Storage Devices <span className='dato'> March 2024</span></h1>
      <section>
        <h2>1. Crucial X10 Pro</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Crucial+X10+Pro" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Samsung T7 Shield</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Samsung+T7+Shield" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default DatastoragedevicesPage;
