import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const HeadphonesPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Headphones May 2024</title>
        <meta name="description" content="Explore the best headphones May 2024, chosen based on expert reviews. Find the perfect headphones for your needs." />
        <meta name="keywords" content="headphones" />
      </Helmet>

      <h1>Top Headphones <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Sony WH-1000XM5</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Sony+WH-1000XM5" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Sennheiser Momentum 4 Wireless</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Sennheiser+Momentum+4+Wireless" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Bose QuietComfort Ultra Headphones</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Bose+QuietComfort+Ultra+Headphones" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. Bowers & Wilkins PX8</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Bowers+&+Wilkins+PX8" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>5. Bose QuietComfort Headphones</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Bose+QuietComfort+Headphones" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default HeadphonesPage;
