import React from 'react';
import '../styles/popularItems.css';

const TopRatedSections = () => {
  return (
    <div><div className="popular-items-container">
<div class="popular-items-header">Top items May 2024</div>
<div class="top-items-intro">
<section><p><span class='page'>Smartphones</span></p>
        <h2>Samsung Galaxy S24 Ultra</h2>
        <p><span className="price-highlight">RR Rating: ★★★★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Samsung+Galaxy+S24+Ultra" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
<section><p><span class='page'>Instant Cameras</span></p>
        <h2>Fujifilm Instax Mini Evo</h2>
        <p><span className="price-highlight">RR Rating: ★★★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Fujifilm+Instax+Mini+Evo" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
<section><p><span class='page'>Action cameras</span></p>
        <h2>DJI Osmo Action 4</h2>
        <p><span className="price-highlight">RR Rating: ★★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=DJI+Osmo+Action+4" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
<section><p><span class='page'>DSLR cameras</span></p>
        <h2>Nikon D850</h2>
        <p><span className="price-highlight">RR Rating: ★★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Nikon+D850" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
<section><p><span class='page'>Graphic Cards</span></p>
        <h2>Nvidia GeForce RTX 4070 Super</h2>
        <p><span className="price-highlight">RR Rating: ★★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Nvidia+GeForce+RTX+4070+Super" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
<section><p><span class='page'>Sports Watches</span></p>
        <h2>Apple Watch Ultra 2</h2>
        <p><span className="price-highlight">RR Rating: ★★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Apple+Watch+Ultra+2" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
<section><p><span class='page'>VR Headsets</span></p>
        <h2>Valve Index</h2>
        <p><span className="price-highlight">RR Rating: ★★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Valve+Index" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
<section><p><span class='page'>Webcams</span></p>
        <h2>Razer Kiyo Pro Ultra</h2>
        <p><span className="price-highlight">RR Rating: ★★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Razer+Kiyo+Pro+Ultra" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
<section><p><span class='page'>Camera Drones</span></p>
        <h2>DJI Mini 4 Pro</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=DJI+Mini+4+Pro" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
</div>
</div></div>
  );
};

export default TopRatedSections;
