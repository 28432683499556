import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const ConvertibletabletsPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Convertible Tablets May 2024</title>
        <meta name="description" content="Explore the best convertible tablets May 2024, chosen based on expert reviews. Find the perfect convertible tablets for your needs." />
        <meta name="keywords" content="convertible tablets" />
      </Helmet>

      <h1>Top Convertible Tablets <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Lenovo Yoga 9i Gen 8</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Lenovo+Yoga+9i+Gen+8" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Acer Chromebook Spin 714</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Acer+Chromebook+Spin+714" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Lenovo Yoga Book 9i</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Lenovo+Yoga+Book+9i" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. Dell XPS 13 2-</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Dell+XPS+13+2-" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default ConvertibletabletsPage;
