import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const BridgecamerasPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Bridge Cameras May 2024</title>
        <meta name="description" content="Explore the best bridge cameras May 2024, chosen based on expert reviews. Find the perfect bridge cameras for your needs." />
        <meta name="keywords" content="bridge cameras" />
      </Helmet>

      <h1>Top Bridge Cameras <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Canon PowerShot SX70 HS</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Canon+PowerShot+SX70+HS" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Sony Cyber-</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Sony+Cyber-" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Nikon Coolpix P1000</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Nikon+Coolpix+P1000" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default BridgecamerasPage;
