import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const StandmixersPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Stand Mixers May 2024</title>
        <meta name="description" content="Explore the best stand mixers May 2024, chosen based on expert reviews. Find the perfect stand mixers for your needs." />
        <meta name="keywords" content="stand mixers" />
      </Helmet>

      <h1>Top Stand Mixers <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. KitchenAid Artisan Series 5-Q</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=KitchenAid+Artisan+Series+5-Q" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Cuisinart Precision Stand Mixer</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Cuisinart+Precision+Stand+Mixer" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. KitchenAid 4</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=KitchenAid+4" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. Kenwood kMix Stand Mixer</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Kenwood+kMix+Stand+Mixer" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default StandmixersPage;
