import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const InstantcamerasPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Instant Cameras May 2024</title>
        <meta name="description" content="Explore the best instant cameras May 2024, chosen based on expert reviews. Find the perfect instant cameras for your needs." />
        <meta name="keywords" content="instant cameras" />
      </Helmet>

      <h1>Top Instant Cameras <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Fujifilm Instax Mini Evo</h2>
        <p><span className="price-highlight">RR Rating: ★★★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Fujifilm+Instax+Mini+Evo" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Fujifilm Instax Mini 12</h2>
        <p><span className="price-highlight">RR Rating: ★★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Fujifilm+Instax+Mini+12" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Polaroid Now</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Polaroid+Now" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. Polaroid Go</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Polaroid+Go" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>5. Fujifilm Instax Square SQ40</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Fujifilm+Instax+Square+SQ40" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default InstantcamerasPage;
