import React, { useState, useEffect, useRef } from 'react';
import '../styles/header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import categories from '../categories.json';
import SearchBar from './SearchBar';
import { Link } from 'react-router-dom';


const Header = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(categories[0]?.name || null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showSearchWindow, setShowSearchWindow] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(prev => !prev);
    if (!isDropdownOpen) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = 'auto'; // Enable scrolling
    }
  };

  const handleCategoryClick = (event, categoryName) => {
    event.stopPropagation();
    setCurrentCategory(categoryName);
  };

  const handleSubSubCategoryClick = () => {
    setDropdownOpen(false);
    document.body.style.overflow = 'auto'; // This should ensure the scrollbar reappears
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        console.log('Click outside dropdown detected');
        setDropdownOpen(false);
        document.body.style.overflow = 'auto'; // Ensure scrollbar is reinstated
    }
};

  const handleScroll = () => {
    const offset = window.pageYOffset;
    setIsScrolled(offset > 50);
  };

  const toggleSearchWindow = () => {
    setShowSearchWindow(prev => !prev);
    if (!showSearchWindow) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };

  const handleSearchInputClick = (event) => {
    // Adjust this value as per your responsive design breakpoints
    if (window.innerWidth <= 600) {
      event.stopPropagation(); // Prevent the event from bubbling up
      setShowSearchWindow(true);
      document.body.style.overflow = 'hidden'; // Prevent background scrolling
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  //useEffect(() => {
  //  window.addEventListener('click', handleClickOutside);
  //  return () => window.removeEventListener('click', handleClickOutside);
  //}, [dropdownRef]);

  return (
    <header>
      <nav>
        <div className="center-nav">
          <div className="dropdown-container" ref={dropdownRef}>
            <button
              className={`menu-button ${isDropdownOpen ? 'menu-open' : ''}`}
              onClick={toggleDropdown}
            >
              <FontAwesomeIcon icon={isDropdownOpen ? faTimes : faBars} style={{ fontSize: '24px' }} />
            </button>

            <Link
            to="/"
            className={`header-link ${currentCategory === null ? 'selected' : ''}`}
            onClick={() => setCurrentCategory(null)}
          >
            ReviewRover
          </Link>

            <div className={`search-bar-wrapper ${isScrolled ? 'hide-on-scroll' : ''}`}>
            <SearchBar onClick={handleSearchInputClick} inSearchWindow={showSearchWindow} onClose={toggleSearchWindow} />
            </div>
          </div>

          <div
            className="dropdown-content"
            style={{ display: isDropdownOpen ? 'block' : 'none' }}
          >
            <div className='center-dropdown-content'>
              <div className="category-column">
                {categories.map((category, index) => (
                  <span
                    key={index}
                    className={`category-link ${currentCategory === category.name ? 'selected' : ''}`}
                    onClick={(e) => handleCategoryClick(e, category.name)}
                  >
                    {category.name}
                  </span>
                ))}
              </div>

              {currentCategory !== null && (
                <div className="subcategory-column">
                <div className="subcategory-header">{currentCategory}</div>
                <div className="subcategory-groups-container">
                  {categories
                    .find(cat => cat.name === currentCategory)
                    .subcategories.map((subcategory, subIndex) => (
                      <div key={subIndex} className="subcategory-group">
                        <span className="subcategory-title">{subcategory.name}</span>
                        {subcategory.subcategories && subcategory.subcategories.map((subSubcategory, subSubIndex) => (
                          <Link
                            key={subSubIndex}
                            to={`/${subSubcategory.link}`} // Endret fra href til to for å bruke Link-komponenten
                            className="second-subcategory-link"
                            onClick={handleSubSubCategoryClick}
                          >
                            {subSubcategory.name}
                          </Link>
                        ))}
                      </div>
                    ))}
                </div>
              </div>
              
              )}
            </div>
          </div>
        </div>

        {/* Mobile search icon, positioned outside of the center-nav */}
        {isScrolled && (
          <FontAwesomeIcon icon={faSearch} className="mobile-search-icon" onClick={toggleSearchWindow} />
        )}
      </nav>

      {/* Full-Screen Search Window */}
      {showSearchWindow && (
        <div className="search-window">
          <SearchBar inSearchWindow={true} onClose={toggleSearchWindow} />
          {/* Additional search-related content here */}
        </div>
      )}
    </header>
  );
};

export default Header;
