import React from 'react';
import { useLocation } from 'react-router-dom';
import '../styles/ads.css';

const Ads = () => {
  const location = useLocation(); 

  if (location.pathname === '/') {
    return (
      <div className='sidebar-ads'>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2823019186515174"
           crossorigin="anonymous"></script>
      </div>
    );
  } else {
    return null; 
  }
}

export default Ads;
