import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const DslrcamerasPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top DSLR cameras May 2024</title>
        <meta name="description" content="Explore the best dslr cameras May 2024, chosen based on expert reviews. Find the perfect dslr cameras for your needs." />
        <meta name="keywords" content="dslr cameras" />
      </Helmet>

      <h1>Top DSLR cameras <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Nikon D850</h2>
        <p><span className="price-highlight">RR Rating: ★★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Nikon+D850" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Nikon D780</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Nikon+D780" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Nikon D7500</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Nikon+D7500" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. Canon EOS 5D Mark IV</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Canon+EOS+5D+Mark+IV" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>5. Nikon D3500</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Nikon+D3500" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default DslrcamerasPage;
