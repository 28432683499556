import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const SportswatchesPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Sports Watches May 2024</title>
        <meta name="description" content="Explore the best sports watches May 2024, chosen based on expert reviews. Find the perfect sports watches for your needs." />
        <meta name="keywords" content="sports watches" />
      </Helmet>

      <h1>Top Sports Watches <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Apple Watch Ultra 2</h2>
        <p><span className="price-highlight">RR Rating: ★★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Apple+Watch+Ultra+2" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Garmin Forerunner 265</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Garmin+Forerunner+265" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Garmin Forerunner 165</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Garmin+Forerunner+165" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. Garmin Forerunner 965</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Garmin+Forerunner+965" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>5. Garmin Instinct 2 Solar</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Garmin+Instinct+2+Solar" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default SportswatchesPage;
