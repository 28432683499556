import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const AirpurifiersPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Air Purifiers May 2024</title>
        <meta name="description" content="Explore the best air purifiers May 2024, chosen based on expert reviews. Find the perfect air purifiers for your needs." />
        <meta name="keywords" content="air purifiers" />
      </Helmet>

      <h1>Top Air Purifiers <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Winix 5500-2</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Winix+5500-2" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Dyson Purifier Cool</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Dyson+Purifier+Cool" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Coway Airmega AP-1512HH M</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Coway+Airmega+AP-1512HH+M" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. Blueair Blue Pure 311i Max</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Blueair+Blue+Pure+311i+Max" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>5. Levoit Vital 200S</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Levoit+Vital+200S" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default AirpurifiersPage;
