import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const GamecontrollersPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Game Controllers May 2024</title>
        <meta name="description" content="Explore the best game controllers May 2024, chosen based on expert reviews. Find the perfect game controllers for your needs." />
        <meta name="keywords" content="game controllers" />
      </Helmet>

      <h1>Top Game Controllers <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. HyperX Clutch Gladiate</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=HyperX+Clutch+Gladiate" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Microsoft Xbox Wireless Controller</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Microsoft+Xbox+Wireless+Controller" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Turtle Beach Stealth Ultra</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Turtle+Beach+Stealth+Ultra" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. Nintendo Switch Pro Controller</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Nintendo+Switch+Pro+Controller" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default GamecontrollersPage;
