import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const BackpacksPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Backpacks March 2024</title>
        <meta name="description" content="Explore the best backpacks March 2024, chosen based on expert reviews. Find the perfect backpacks for your needs." />
        <meta name="keywords" content="backpacks" />
      </Helmet>

      <h1>Top Backpacks <span className='dato'> March 2024</span></h1>
      <section>
        <h2>1. Gregory Paragon 58</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Gregory+Paragon+58" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Osprey Exos 58/E</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Osprey+Exos+58/E" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default BackpacksPage;
