import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const GamingchairsPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Gaming Chairs May 2024</title>
        <meta name="description" content="Explore the best gaming chairs May 2024, chosen based on expert reviews. Find the perfect gaming chairs for your needs." />
        <meta name="keywords" content="gaming chairs" />
      </Helmet>

      <h1>Top Gaming Chairs <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Secretlab Titan Evo 2022</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Secretlab+Titan+Evo+2022" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Corsair TC100 Relaxed</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Corsair+TC100+Relaxed" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Secretlab Titan Evo</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Secretlab+Titan+Evo" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. Razer Iskur V2</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Razer+Iskur+V2" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default GamingchairsPage;
