import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const ShotgunmicrophonesPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Shotgun Microphones March 2024</title>
        <meta name="description" content="Explore the best shotgun microphones March 2024, chosen based on expert reviews. Find the perfect shotgun microphones for your needs." />
        <meta name="keywords" content="shotgun microphones" />
      </Helmet>

      <h1>Top Shotgun Microphones <span className='dato'> March 2024</span></h1>
      <section>
        <h2>1. Rode VideoMic Pro</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Rode+VideoMic+Pro" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Sennheiser MKE 600</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Sennheiser+MKE+600" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default ShotgunmicrophonesPage;
