import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const MousePage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Mouse May 2024</title>
        <meta name="description" content="Explore the best mouse May 2024, chosen based on expert reviews. Find the perfect mouse for your needs." />
        <meta name="keywords" content="mouse" />
      </Helmet>

      <h1>Top Mouse <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Razer DeathAdder V3 Pro</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Razer+DeathAdder+V3+Pro" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Roccat Kone XP</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Roccat+Kone+XP" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Logitech G305 Lightspeed</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Logitech+G305+Lightspeed" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. Logitech G203 Lightsync</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Logitech+G203+Lightsync" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default MousePage;
