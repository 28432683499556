import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const GamingmicePage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Gaming Mice May 2024</title>
        <meta name="description" content="Explore the best gaming mice May 2024, chosen based on expert reviews. Find the perfect gaming mice for your needs." />
        <meta name="keywords" content="gaming mice" />
      </Helmet>

      <h1>Top Gaming Mice <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Razer DeathAdder V3 Pro</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Razer+DeathAdder+V3+Pro" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Razer Basilisk V3</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Razer+Basilisk+V3" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Razer Naga V2 Pro</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Razer+Naga+V2+Pro" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. Logitech G Pro X Superlight 2</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Logitech+G+Pro+X+Superlight+2" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>5. Logitech G305 Lightspeed</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Logitech+G305+Lightspeed" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default GamingmicePage;
