import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const SpaceheatersPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Space Heaters March 2024</title>
        <meta name="description" content="Explore the best space heaters March 2024, chosen based on expert reviews. Find the perfect space heaters for your needs." />
        <meta name="keywords" content="space heaters" />
      </Helmet>

      <h1>Top Space Heaters <span className='dato'> March 2024</span></h1>
      <section>
        <h2>1. Dreo DR-HSH004A</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Dreo+DR-HSH004A" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. DeLonghi Ceramic Tower Heater</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=DeLonghi+Ceramic+Tower+Heater" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default SpaceheatersPage;
