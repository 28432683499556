import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const GamingheadsetsPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Gaming Headsets May 2024</title>
        <meta name="description" content="Explore the best gaming headsets May 2024, chosen based on expert reviews. Find the perfect gaming headsets for your needs." />
        <meta name="keywords" content="gaming headsets" />
      </Helmet>

      <h1>Top Gaming Headsets <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. HyperX Cloud Alpha Wireless</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=HyperX+Cloud+Alpha+Wireless" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. SteelSeries Arctis Nova Pro Wireless</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=SteelSeries+Arctis+Nova+Pro+Wireless" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Corsair Virtuoso Pro</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Corsair+Virtuoso+Pro" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. Razer BlackShark V2 Pro</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Razer+BlackShark+V2+Pro" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>5. HyperX Cloud Alpha</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=HyperX+Cloud+Alpha" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default GamingheadsetsPage;
