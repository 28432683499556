import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const MotherboardsPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Motherboards May 2024</title>
        <meta name="description" content="Explore the best motherboards May 2024, chosen based on expert reviews. Find the perfect motherboards for your needs." />
        <meta name="keywords" content="motherboards" />
      </Helmet>

      <h1>Top Motherboards <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Gigabyte B650E Aorus Master</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Gigabyte+B650E+Aorus+Master" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Gigabyte X670E Aorus Pro X</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Gigabyte+X670E+Aorus+Pro+X" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Asus ROG Strix B550-F G</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Asus+ROG+Strix+B550-F+G" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. NZXT N7 B550</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=NZXT+N7+B550" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default MotherboardsPage;
