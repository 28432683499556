import React from 'react';
import { Link } from 'react-router-dom'; // Legg til denne importen
import '../styles/footer.css';

const Footer = () => {
  const linkStyle = {
    marginLeft: '10px', // Juster verdien etter behov
    color: 'white',    // Sett tekstfargen til hvit

  };

  return (
    <footer className="footer">
      <span style={{ color: 'white' }}>&copy;ReviewRover 2023 </span>

      <Link to="/about" style={linkStyle}>How does it work?</Link>
    </footer>
  );
};

export default Footer;
