import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const GraphiccardsPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Graphic Cards May 2024</title>
        <meta name="description" content="Explore the best graphic cards May 2024, chosen based on expert reviews. Find the perfect graphic cards for your needs." />
        <meta name="keywords" content="graphic cards" />
      </Helmet>

      <h1>Top Graphic Cards <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Nvidia GeForce RTX 4070 Super</h2>
        <p><span className="price-highlight">RR Rating: ★★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Nvidia+GeForce+RTX+4070+Super" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Nvidia GeForce RTX 4090</h2>
        <p><span className="price-highlight">RR Rating: ★★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Nvidia+GeForce+RTX+4090" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. AMD Radeon RX 7900 GRE</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=AMD+Radeon+RX+7900+GRE" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. AMD Radeon RX 7900 XTX</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=AMD+Radeon+RX+7900+XTX" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>5. Nvidia GeForce RTX 4080 Super</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Nvidia+GeForce+RTX+4080+Super" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default GraphiccardsPage;
