import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const GaminglaptopsPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Gaming Laptops May 2024</title>
        <meta name="description" content="Explore the best gaming laptops May 2024, chosen based on expert reviews. Find the perfect gaming laptops for your needs." />
        <meta name="keywords" content="gaming laptops" />
      </Helmet>

      <h1>Top Gaming Laptops <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Alienware m16 R2</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Alienware+m16+R2" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. ASUS ROG Zephyrus G14</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=ASUS+ROG+Zephyrus+G14" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Razer Blade 16</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Razer+Blade+16" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. Asus ROG Strix Scar 18</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Asus+ROG+Strix+Scar+18" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>5. HP Omen Transcend 14</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=HP+Omen+Transcend+14" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default GaminglaptopsPage;
