import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const CameradronesPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Camera Drones May 2024</title>
        <meta name="description" content="Explore the best camera drones May 2024, chosen based on expert reviews. Find the perfect camera drones for your needs." />
        <meta name="keywords" content="camera drones" />
      </Helmet>

      <h1>Top Camera Drones <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. DJI Mini 4 Pro</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=DJI+Mini+4+Pro" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. DJI Mini 3</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=DJI+Mini+3" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. DJI Mavic 3 Classic</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=DJI+Mavic+3+Classic" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. DJI Air 3</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=DJI+Air+3" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>5. DJI Mavic 3 Pro</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=DJI+Mavic+3+Pro" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default CameradronesPage;
