import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import categories from '../categories.json';
import '../styles/sb.css';

const SearchBar = ({ inSearchWindow, onClose, onClick }) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const searchBarRef = useRef(null);
  const navigate = useNavigate(); // Use the useNavigate hook for navigation

  const handleInputChange = (event) => {
    const newQuery = event.target.value.toLowerCase();
    setQuery(newQuery);

    if (newQuery.trim() === '') {
      setSuggestions([]);
      return;
    }

    const newSuggestions = categories.flatMap(category =>
      category.subcategories.flatMap(subcategory =>
        subcategory.subcategories?.filter(subSubcategory =>
          subSubcategory.name.toLowerCase().includes(newQuery)
        ) || []
      )
    ).slice(0, 6); // Limit the number of suggestions

    setSuggestions(newSuggestions);
  };

  const handleSuggestionClick = (path) => {
    navigate(path);
    window.scrollTo({
      top: 0,
    });
    setQuery(''); // Clear the search bar
    setSuggestions([]); // Clear suggestions
    if (inSearchWindow && onClose) {
      onClose(); // Close the search window if open
    }
  };

  const handleClearInput = () => {
    setQuery('');
    setSuggestions([]); // Also clear the suggestions
  };

  const handleClickOutside = (event) => {
    if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (inSearchWindow) {
      setTimeout(() => {
        const inputElement = searchBarRef.current?.querySelector('input.search-input');
        if (document.activeElement === inputElement) {
          inputElement.blur();
        }
      }, 10); // The delay can be adjusted based on your UI/UX needs
    }
  }, [inSearchWindow]);

  return (
    <div className="search-bar-container" ref={searchBarRef}>
      {inSearchWindow && (
        <span className="search-back-arrow" onClick={onClose}>&lt;</span>
      )}
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        onClick={onClick} // Attach the onClick handler here
        placeholder="Search for products..."
        className={`search-input ${inSearchWindow ? 'with-arrow' : ''}`}
      />
      {query && (
        <button className="clear-button" onClick={handleClearInput}>
          X
        </button>
      )}
      {suggestions.length > 0 && (
        <div className="suggestions-container">
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              className="suggestion"
              onClick={() => handleSuggestionClick(suggestion.link)}
            >
              {suggestion.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
