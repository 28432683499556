import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const WafflemakersPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Waffle Makers March 2024</title>
        <meta name="description" content="Explore the best waffle makers March 2024, chosen based on expert reviews. Find the perfect waffle makers for your needs." />
        <meta name="keywords" content="waffle makers" />
      </Helmet>

      <h1>Top Waffle Makers <span className='dato'> March 2024</span></h1>
      <section>
        <h2>1. Ninja NeverStick PRO Belgian Waffle Maker</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Ninja+NeverStick+PRO+Belgian+Waffle+Maker" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Cuisinart Double Flip Belgian Waffle Maker</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Cuisinart+Double+Flip+Belgian+Waffle+Maker" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default WafflemakersPage;
