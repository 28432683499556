import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const TabletsPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Tablets May 2024</title>
        <meta name="description" content="Explore the best tablets May 2024, chosen based on expert reviews. Find the perfect tablets for your needs." />
        <meta name="keywords" content="tablets" />
      </Helmet>

      <h1>Top Tablets <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. OnePlus Pad</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=OnePlus+Pad" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Samsung Galaxy Tab S9 Ultra</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Samsung+Galaxy+Tab+S9+Ultra" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Google Pixel Tablet</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Google+Pixel+Tablet" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. Amazon Fire 7</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Amazon+Fire+7" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>5. Microsoft Surface Pro 9</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Microsoft+Surface+Pro+9" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default TabletsPage;
