import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const ComputercasesPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Computer Cases March 2024</title>
        <meta name="description" content="Explore the best computer cases March 2024, chosen based on expert reviews. Find the perfect computer cases for your needs." />
        <meta name="keywords" content="computer cases" />
      </Helmet>

      <h1>Top Computer Cases <span className='dato'> March 2024</span></h1>
      <section>
        <h2>1. NZXT H7 Flow</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=NZXT+H7+Flow" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Fractal Design Meshify 2</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Fractal+Design+Meshify+2" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Corsair 7000D Airflow</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Corsair+7000D+Airflow" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. NZXT H710i</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=NZXT+H710i" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default ComputercasesPage;
