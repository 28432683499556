import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/stylesIndex.css';

const About = () => {
  return (
    <div className="Introduction">
      <Helmet>
        <title>ReviewRover: Your Guide to Smart Shopping</title>
        <meta name="description" content="Discover the best products with ReviewRover, your AI-powered companion in the world of reviews and recommendations." />
        <meta name="keywords" content="smart shopping, product reviews, AI recommendations, tech trends, about" />
      </Helmet>
      <style>
        {`
          .Introduction {
            font-family: 'Arial, sans-serif';
            background-color: #f7f7f7;
          }

          .intro-container {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            margin: 20px;
          }

          .intro-section {
            flex: 1;
            padding: 10px;
          }

          .intro-image {
            background-image: url('/about_bilde.png');
            background-size: cover;
            background-position: center;
            height: 600px;
          }

          .intro-text {
            font-size: 20px;
            color: #555;
            width: 2000px;
          }

          .main-title {
            font-size: 28px;
            margin-bottom: 15px;
            color: #333;
          }

          .page-list {
            list-style: none;
            padding: 0;
          }

          .page-list-item {
            margin-bottom: 10px;
            font-size: 18px;
            color: #007bff;
          }

          /* Mobile Responsive Adjustments */
          @media only screen and (max-width: 768px) {
            .intro-container {
              flex-direction: column;
            }

            .intro-section {
              width: 100%;
            }

            .intro-image {
              height: 300px; /* Adjust as needed */
            }
          }
        `}
      </style>
      <div className="intro-container">
        <div className="intro-section intro-text">
          <h1 className="about-title">How does it work?</h1>
          <p>
            ReviewRover uses AI to find products with good reviews that are mentioned frequently across the web. This way a collective opinion is presented without any biases that a single person or website might present. The products RR finds are always double checked, to ensure a good level of accuracy and reliability in the recommendations.
          </p>
          
          <p>If a product has more stars, or is higher up on a list, it doesn't necessarily mean one is better than the other. It could simply mean that the product with the most stars had more people talking positively about it at the time RR did its research.</p>

          <p>If a product has a lot of stars it's likely being praised at many different blogs and top webpages.</p>
        
          <p>If a product has few stars, it's likely positively reviewed, but doesn't stand out as much in terms of positive mentions compared to other products.</p>
        </div>
        <div className="intro-section intro-image">
          {/* Image is displayed as a background */}
        </div>
      </div>
    </div>
  );
};

export default About;
