import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const DehumidifiersPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Dehumidifiers May 2024</title>
        <meta name="description" content="Explore the best dehumidifiers May 2024, chosen based on expert reviews. Find the perfect dehumidifiers for your needs." />
        <meta name="keywords" content="dehumidifiers" />
      </Helmet>

      <h1>Top Dehumidifiers <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Frigidaire 35-P</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Frigidaire+35-P" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. LG PuriCare UD501KOG5</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=LG+PuriCare+UD501KOG5" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default DehumidifiersPage;
