import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Ads from './header/ads';
import Footer from './components/Footer';
import Header from './components/Header';
import * as Pages from './pages';
import './styles/stylesIndex.css';
import { Helmet } from 'react-helmet';
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <Router>
      <div>
      <Helmet>
        <title>ReviewRover: Your Guide to Smart Shopping</title>
        <meta name="description" content="Welcome to ReviewRover! Embark on a journey of smart shopping with ReviewRover, where AI meets reviews to fetch you the crème de la crème of products" />
        <meta name="keywords" content="Topreviews" />
      </Helmet>
        <Header />
        
        <div className="top-content-container"> {/* New container for flex layout */}
          <Ads/>
          <main> 
            <Routes>
              <Route path="/" element={<Pages.Introduction />} />
              <Route path="/about" element={<Pages.About />} />
            <Route path="/products/graphiccards" element={<Pages.GraphiccardsPage />} />
            <Route path="/products/smartphones" element={<Pages.SmartphonesPage />} />
            <Route path="/products/gaminglaptops" element={<Pages.GaminglaptopsPage />} />
            <Route path="/products/treadmills" element={<Pages.TreadmillsPage />} />
            <Route path="/products/actioncameras" element={<Pages.ActioncamerasPage />} />
            <Route path="/products/ruggedphones" element={<Pages.RuggedphonesPage />} />
            <Route path="/products/processors" element={<Pages.ProcessorsPage />} />
            <Route path="/products/gamingdesktops" element={<Pages.GamingdesktopsPage />} />
            <Route path="/products/dslrcameras" element={<Pages.DslrcamerasPage />} />
            <Route path="/products/coffeemakers" element={<Pages.CoffeemakersPage />} />
            <Route path="/products/smartwatches" element={<Pages.SmartwatchesPage />} />
            <Route path="/products/motherboards" element={<Pages.MotherboardsPage />} />
            <Route path="/products/gamingmice" element={<Pages.GamingmicePage />} />
            <Route path="/products/mirrorlesscameras" element={<Pages.MirrorlesscamerasPage />} />
            <Route path="/products/fitnesstrackers" element={<Pages.FitnesstrackersPage />} />
            <Route path="/products/sportswatches" element={<Pages.SportswatchesPage />} />
            <Route path="/products/instantcameras" element={<Pages.InstantcamerasPage />} />
            <Route path="/products/gamingkeyboards" element={<Pages.GamingkeyboardsPage />} />
            <Route path="/products/headphones" element={<Pages.HeadphonesPage />} />
            <Route path="/products/bridgecameras" element={<Pages.BridgecamerasPage />} />
            <Route path="/products/powersupplies" element={<Pages.PowersuppliesPage />} />
            <Route path="/products/tablets" element={<Pages.TabletsPage />} />
            <Route path="/products/shotgunmicrophones" element={<Pages.ShotgunmicrophonesPage />} />
            <Route path="/products/gamingheadsets" element={<Pages.GamingheadsetsPage />} />
            <Route path="/products/lavaliermicrophones" element={<Pages.LavaliermicrophonesPage />} />
            <Route path="/products/computercases" element={<Pages.ComputercasesPage />} />
            <Route path="/products/convertibletablets" element={<Pages.ConvertibletabletsPage />} />
            <Route path="/products/harddrives" element={<Pages.HarddrivesPage />} />
            <Route path="/products/audiorecorders" element={<Pages.AudiorecordersPage />} />
            <Route path="/products/solidstatedrives" element={<Pages.SolidstatedrivesPage />} />
            <Route path="/products/gamingchairs" element={<Pages.GamingchairsPage />} />
            <Route path="/products/cameradrones" element={<Pages.CameradronesPage />} />
            <Route path="/products/externalharddrives" element={<Pages.ExternalharddrivesPage />} />
            <Route path="/products/greenscreens" element={<Pages.GreenscreensPage />} />
            <Route path="/products/vrheadsets" element={<Pages.VrheadsetsPage />} />
            <Route path="/products/scanners" element={<Pages.ScannersPage />} />
            <Route path="/products/gamingmonitors" element={<Pages.GamingmonitorsPage />} />
            <Route path="/products/datastoragedevices" element={<Pages.DatastoragedevicesPage />} />
            <Route path="/products/hikingshoes" element={<Pages.HikingshoesPage />} />
            <Route path="/products/webcams" element={<Pages.WebcamsPage />} />
            <Route path="/products/routers" element={<Pages.RoutersPage />} />
            <Route path="/products/espressomachines" element={<Pages.EspressomachinesPage />} />
            <Route path="/products/mouse" element={<Pages.MousePage />} />
            <Route path="/products/keyboards" element={<Pages.KeyboardsPage />} />
            <Route path="/products/smarthomethermostats" element={<Pages.SmarthomethermostatsPage />} />
            <Route path="/products/exercisebikes" element={<Pages.ExercisebikesPage />} />
            <Route path="/products/backpacks" element={<Pages.BackpacksPage />} />
            <Route path="/products/usbflashdrives" element={<Pages.UsbflashdrivesPage />} />
            <Route path="/products/gamingmousepads" element={<Pages.GamingmousepadsPage />} />
            <Route path="/products/blenders" element={<Pages.BlendersPage />} />
            <Route path="/products/washingmachines" element={<Pages.WashingmachinesPage />} />
            <Route path="/products/gamecontrollers" element={<Pages.GamecontrollersPage />} />
            <Route path="/products/yogamats" element={<Pages.YogamatsPage />} />
            <Route path="/products/climbinggear" element={<Pages.ClimbinggearPage />} />
            <Route path="/products/electricpressurecookers" element={<Pages.ElectricpressurecookersPage />} />
            <Route path="/products/airpurifiers" element={<Pages.AirpurifiersPage />} />
            <Route path="/products/cameratripods" element={<Pages.CameratripodsPage />} />
            <Route path="/products/standmixers" element={<Pages.StandmixersPage />} />
            <Route path="/products/spaceheaters" element={<Pages.SpaceheatersPage />} />
            <Route path="/products/headphones" element={<Pages.HeadphonesPage />} />
            <Route path="/products/affordablegamingheadsets" element={<Pages.AffordablegamingheadsetsPage />} />
            <Route path="/products/thermalpaste" element={<Pages.ThermalpastePage />} />
            <Route path="/products/wafflemakers" element={<Pages.WafflemakersPage />} />
            <Route path="/products/networkattachedstorage" element={<Pages.NetworkattachedstoragePage />} />
            <Route path="/products/juicers" element={<Pages.JuicersPage />} />
            <Route path="/products/portabledishwashers" element={<Pages.PortabledishwashersPage />} />
            <Route path="/products/memorycards" element={<Pages.MemorycardsPage />} />
            <Route path="/products/vacuumcleaners" element={<Pages.VacuumcleanersPage />} />
            <Route path="/products/dehumidifiers" element={<Pages.DehumidifiersPage />} />
            <Route path="/products/dryers" element={<Pages.DryersPage />} />
            </Routes>
          </main>
          <Ads/>
        </div>
        <Footer />
        <Analytics />
      </div>
    </Router>
  );
}

export default App;