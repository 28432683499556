import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const ProcessorsPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Processors May 2024</title>
        <meta name="description" content="Explore the best processors May 2024, chosen based on expert reviews. Find the perfect processors for your needs." />
        <meta name="keywords" content="processors" />
      </Helmet>

      <h1>Top Processors <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. AMD Ryzen 7 5800X3D</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=AMD+Ryzen+7+5800X3D" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. AMD Ryzen 7 7800X3D</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=AMD+Ryzen+7+7800X3D" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. AMD Ryzen 9 7950X3D</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=AMD+Ryzen+9+7950X3D" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. Intel Core i5-13600K</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Intel+Core+i5-13600K" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>5. AMD Ryzen 5 8600G</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=AMD+Ryzen+5+8600G" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default ProcessorsPage;
