import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const SmarthomethermostatsPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Smart Home Thermostats May 2024</title>
        <meta name="description" content="Explore the best smart home thermostats May 2024, chosen based on expert reviews. Find the perfect smart home thermostats for your needs." />
        <meta name="keywords" content="smart home thermostats" />
      </Helmet>

      <h1>Top Smart Home Thermostats <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Ecobee Smart Thermostat Premium</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Ecobee+Smart+Thermostat+Premium" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Nest Learning Thermostat</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Nest+Learning+Thermostat" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default SmarthomethermostatsPage;
