import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const CameratripodsPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Camera Tripods March 2024</title>
        <meta name="description" content="Explore the best camera tripods March 2024, chosen based on expert reviews. Find the perfect camera tripods for your needs." />
        <meta name="keywords" content="camera tripods" />
      </Helmet>

      <h1>Top Camera Tripods <span className='dato'> March 2024</span></h1>
      <section>
        <h2>1. Manfrotto Element MII</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Manfrotto+Element+MII" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Manfrotto B PIXI Mini Tripod</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Manfrotto+B+PIXI+Mini+Tripod" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default CameratripodsPage;
