// Introduction.js
import React from 'react';
import { Helmet } from 'react-helmet';
import './styles/stylesIndex.css';
import './styles/introduction.css';
import PopularCategories from './components/PopularCategories'; // Adjust the import path as necessary
import TopRatedSections from './components/TopRatedSections';

const Introduction = () => {
  return (
    <div className="Introduction">
      <Helmet>
        {/* Helmet content remains the same */}
      </Helmet>
      <div className="intro-container">
        <div className="main-title"><h1 className='intro-title'>Find the best reviewed products every month</h1></div>
        <PopularCategories />
        <div className='sep'></div>
        <TopRatedSections />
        {/* The rest of your Introduction content */}
      </div>
    </div>
  );
};

export default Introduction;
