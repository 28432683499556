import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const AudiorecordersPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Audio Recorders March 2024</title>
        <meta name="description" content="Explore the best audio recorders March 2024, chosen based on expert reviews. Find the perfect audio recorders for your needs." />
        <meta name="keywords" content="audio recorders" />
      </Helmet>

      <h1>Top Audio Recorders <span className='dato'> March 2024</span></h1>
      <section>
        <h2>1. Sony ICD-PX470</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Sony+ICD-PX470" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Tascam DR-40X</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Tascam+DR-40X" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Zoom H1N</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Zoom+H1N" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default AudiorecordersPage;
