import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const WashingmachinesPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Washing Machines March 2024</title>
        <meta name="description" content="Explore the best washing machines March 2024, chosen based on expert reviews. Find the perfect washing machines for your needs." />
        <meta name="keywords" content="washing machines" />
      </Helmet>

      <h1>Top Washing Machines <span className='dato'> March 2024</span></h1>
      <section>
        <h2>1. Bosch 8 kg 5 Star Fully-A</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Bosch+8+kg+5+Star+Fully-A" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. LG WM3400CW</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=LG+WM3400CW" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default WashingmachinesPage;
