import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const RoutersPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Routers March 2024</title>
        <meta name="description" content="Explore the best routers March 2024, chosen based on expert reviews. Find the perfect routers for your needs." />
        <meta name="keywords" content="routers" />
      </Helmet>

      <h1>Top Routers <span className='dato'> March 2024</span></h1>
      <section>
        <h2>1. Asus RT-AX59U</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Asus+RT-AX59U" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Asus TUF Gaming AX5400</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Asus+TUF+Gaming+AX5400" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Netgear Nighthawk RAXE500</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Netgear+Nighthawk+RAXE500" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default RoutersPage;
