import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const MemorycardsPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Memory cards May 2024</title>
        <meta name="description" content="Explore the best memory cards May 2024, chosen based on expert reviews. Find the perfect memory cards for your needs." />
        <meta name="keywords" content="memory cards" />
      </Helmet>

      <h1>Top Memory cards <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Lexar Professional Class 10 UHS-II 2000X</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Lexar+Professional+Class+10+UHS-II+2000X" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. SanDisk Extreme Pro</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=SanDisk+Extreme+Pro" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default MemorycardsPage;
