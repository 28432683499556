import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const VrheadsetsPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top VR Headsets May 2024</title>
        <meta name="description" content="Explore the best vr headsets May 2024, chosen based on expert reviews. Find the perfect vr headsets for your needs." />
        <meta name="keywords" content="vr headsets" />
      </Helmet>

      <h1>Top VR Headsets <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Valve Index</h2>
        <p><span className="price-highlight">RR Rating: ★★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Valve+Index" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. HTC Vive Pro 2</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=HTC+Vive+Pro+2" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default VrheadsetsPage;
