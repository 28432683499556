import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const ClimbinggearPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Climbing Gear April 2024</title>
        <meta name="description" content="Explore the best climbing gear April 2024, chosen based on expert reviews. Find the perfect climbing gear for your needs." />
        <meta name="keywords" content="climbing gear" />
      </Helmet>

      <h1>Top Climbing Gear <span className='dato'> April 2024</span></h1>
      <section>
        <h2>1. Mammut 9</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Mammut+9" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Petzl GriGri</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Petzl+GriGri" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Black Diamond Gridlock Screwgate Carabiner</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Black+Diamond+Gridlock+Screwgate+Carabiner" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default ClimbinggearPage;
