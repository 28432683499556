import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const AffordablegamingheadsetsPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Affordable gaming headsets May 2024</title>
        <meta name="description" content="Explore the best affordable gaming headsets May 2024, chosen based on expert reviews. Find the perfect affordable gaming headsets for your needs." />
        <meta name="keywords" content="affordable gaming headsets" />
      </Helmet>

      <h1>Top Affordable gaming headsets <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Razer BlackShark V2 Pro</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Razer+BlackShark+V2+Pro" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. HyperX Cloud Alpha Wireless</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=HyperX+Cloud+Alpha+Wireless" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Roccat Elo 7</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Roccat+Elo+7" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default AffordablegamingheadsetsPage;
