import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const WebcamsPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Webcams May 2024</title>
        <meta name="description" content="Explore the best webcams May 2024, chosen based on expert reviews. Find the perfect webcams for your needs." />
        <meta name="keywords" content="webcams" />
      </Helmet>

      <h1>Top Webcams <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Razer Kiyo Pro Ultra</h2>
        <p><span className="price-highlight">RR Rating: ★★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Razer+Kiyo+Pro+Ultra" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Logitech StreamCam</h2>
        <p><span className="price-highlight">RR Rating: ★★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Logitech+StreamCam" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Logitech Brio 500</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Logitech+Brio+500" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. Elgato Facecam MK</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Elgato+Facecam+MK" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>5. Logitech C920s Pro HD</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Logitech+C920s+Pro+HD" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default WebcamsPage;
