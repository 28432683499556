import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const ActioncamerasPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Action cameras May 2024</title>
        <meta name="description" content="Explore the best action cameras May 2024, chosen based on expert reviews. Find the perfect action cameras for your needs." />
        <meta name="keywords" content="action cameras" />
      </Helmet>

      <h1>Top Action cameras <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. DJI Osmo Action 4</h2>
        <p><span className="price-highlight">RR Rating: ★★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=DJI+Osmo+Action+4" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. GoPro Hero12 Black</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=GoPro+Hero12+Black" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Insta360 Go 3</h2>
        <p><span className="price-highlight">RR Rating: ★★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Insta360+Go+3" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. Insta360 X4</h2>
        <p><span className="price-highlight">RR Rating: ★★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Insta360+X4" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>5. GoPro Hero10 Black</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=GoPro+Hero10+Black" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default ActioncamerasPage;
