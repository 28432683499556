import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/products.css';

const VacuumcleanersPage = () => {
  return (
    <div class="product-container">
      <Helmet>
        <title>Top Vacuum Cleaners May 2024</title>
        <meta name="description" content="Explore the best vacuum cleaners May 2024, chosen based on expert reviews. Find the perfect vacuum cleaners for your needs." />
        <meta name="keywords" content="vacuum cleaners" />
      </Helmet>

      <h1>Top Vacuum Cleaners <span className='dato'> May 2024</span></h1>
      <section>
        <h2>1. Shark Stratos Upright Vacuum</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Shark+Stratos+Upright+Vacuum" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>2. Dyson 360 Vis Nav</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Dyson+360+Vis+Nav" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>3. Shark Navigator Lift-A</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Shark+Navigator+Lift-A" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>4. Kenmore Elite Cordless Stick Vacuum</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Kenmore+Elite+Cordless+Stick+Vacuum" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
      <section>
        <h2>5. Dyson V15 Detect</h2>
        <p><span className="price-highlight">RR Rating: ★★</span></p>
        <p><span className="buy-now">Buy now: <a href="https://www.amazon.com/s?k=Dyson+V15+Detect" target="_blank" rel="noopener noreferrer">Amazon</a></span></p>
      </section>
    </div>
  );
};

export default VacuumcleanersPage;
