import React, { useRef } from 'react';
import { Link } from 'react-router-dom'; // Import Link
import '../styles/popularCategories.css'; // Sjekk at denne stien er korrekt

const PopularCategories = () => {
  const scrollContainerRef = useRef(null);

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    const itemWidth = container.querySelector('li').offsetWidth * 2;
    
    const newScrollPosition = direction === 'left'
      ? container.scrollLeft - itemWidth
      : container.scrollLeft + itemWidth;
    
    container.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth'
    });
  };
  const folder = "knapp_ikoner";
  const categoryLinks = [
    { name: "Smartphones", url: "/products/smartphones", img: `${folder}/iphon.png` },
    { name: "Headphones", url: "/products/headphones", img: `${folder}/headphon.png` },
    { name: "Smartwatches", url: "/products/smartwatches", img: `${folder}/smartcloc.png` },
    { name: "Tablets", url: "/products/tablets", img: `${folder}/tablet.png` },
    { name: "Action Cameras", url: "/products/actioncameras", img: `${folder}/actionc.png` }
    // Legg til flere kategorier etter behov
  ];

  return (
    <div className="popular-categories">
      <div className="popular-categories-header">Popular Categories</div>
      <div className="scroll-wrapper">
        <button className="scroll-btn left" onClick={() => scroll('left')}>{'<'}</button>
        <div className="scroll-container" ref={scrollContainerRef}>
          <ul className='popular-categories-list'>
            {categoryLinks.map((category) => (
              <li key={category.name}>
                <Link to={category.url} className="popular-category-link">
                  <div className="category-image">
                    <img src={category.img} alt={category.name} />
                  </div>
                  <div className="category-text">{category.name}</div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <button className="scroll-btn right" onClick={() => scroll('right')}>{'>'}</button>
      </div>
    </div>
  );
};

export default PopularCategories;
